import React, {PropsWithChildren, useCallback, useState} from 'react';
import Streaming from "../../components/Streaming";
import styles from "./Shortlist.module.css";
import ChannelsList from "../../components/ChannelsList";
import {playlistShortlistService} from "../../services/playlist/PlaylistShortlistService";
import {M3uMedia} from "../../lib/m3u";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import {AddToShortlist} from "../../components/ManualAddToShortlist";
import {WordFilter} from "../../components/WordFilter";

interface Props extends PropsWithChildren<any> {

}

function Shortlist(props: Props) {

    const [rerenderTrigger, setRerenderTrigger] = useState(0);
    const [filterWords, setFilterWords] = useState<string[]>([]);

    const removeFromShortlist = useCallback((channel: M3uMedia) => {
        console.log(`removeFromShortlist - Clicked on ${channel.name}`);
        playlistShortlistService.remove(channel).then(() => {
            // Force a re-render of the Shortlist component
            setRerenderTrigger(prev => prev + 1);
        });
    }, []);

    const refreshShortlist = (channel: M3uMedia) => {
        console.log("Refreshing shortlist")
        // Force a re-render of the Shortlist component
        setRerenderTrigger(prev => prev + 1);
    };

    const ChannelActions = ({data}: { data: M3uMedia }) => (
        <div className={styles.channelActions}>
            <div className={styles.tooltipContainer} title="Fjern fra shortlist">
                <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() => removeFromShortlist(data)}
                    className={styles.removeIcon}
                />
            </div>
        </div>
    );

    const handleFilterSubmit = (words: string[]) => {
        setFilterWords(words);
    };

    return (
        <div className={styles.boxcontainer}>
            <div className={`${styles.box} ${styles.leftBox}`}>
                <div className={styles.inputContainer}>
                    <AddToShortlist useCollapsable={true} onAdded={refreshShortlist}/>
                    <WordFilter onSubmit={handleFilterSubmit}/>
                </div>
                <div id ="channelsListContainer" className={styles.channelsListContainer}>
                    <ChannelsList
                        RowActionsComponent={ChannelActions}
                        playlistService={playlistShortlistService}
                        key={rerenderTrigger}
                        wordsFilter={filterWords}
                    />
                </div>
            </div>
            <div className={`${styles.box} ${styles.rightBox}`}>
                <Streaming/>
            </div>
        </div>
    );
}

export default Shortlist;
