import React from 'react';
import "../../App.css";
import CreateAccount from "./CreateAccount";
import DeleteAccount from "./DeleteAccount";
import {AccountContext} from "../../context/account/AccountContext";


const AccountView = () => {
    const {data, removeAccount, createAccount} = React.useContext(AccountContext);

    const haveAccountAlreadyStored = () => {
        if (data) {
            return true
        }
        return false
    }

    return (
           <>
            {haveAccountAlreadyStored() ? (
                <DeleteAccount onDeleteAccount={() => removeAccount()}/>
            ) : (
                <CreateAccount onSaveAccount={(a) => createAccount(a)}/>
            )}
           </>

    );
}

export default AccountView;
