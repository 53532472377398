import React from 'react';
import Statistics from "../../components/Statistics";
import styles from "./home.module.css";
import AccountView from "../../components/Account";
import DownloadPlaylist from "../../components/DownloadPlaylist";
import Collapsable from "../../components/common/Collapsable";
import Loading from "../../components/common/Loading";

function Home() {

    return (
        <div className={styles.container}>
            <div className={styles.screen__content}>
                <Statistics/>
                <Collapsable defaultExpanded={true} maximizedText={'Kontoadministrasjon'} minimizedText={'Trykk her for Kontoadministrasjon'}>
                    <>
                        <AccountView/>
                        <DownloadPlaylist/>
                    </>
                </Collapsable>
            </div>
        </div>
    );
}

export default Home;
