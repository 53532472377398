import React from 'react';

import styles from "./button.module.css";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    text: string;
    icon?: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({text, icon, ...props}) => {

    return (
        <div className={`${styles.button__wrapper}`}>
            <button {...props} className={`${styles.button}`}>
                <span className={styles.button__text}>{text}</span>
                {icon && <span className={styles.button__icon}>{icon}</span>}
            </button>
        </div>
    );
}

export default Button;
