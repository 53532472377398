import {M3uMedia} from "../../lib/m3u/m3u-model";
import Input from "../common/Input";
import React, {useCallback} from "react";
import styles from "./manual.add.shortlist.module.css";
import {playlistShortlistService} from "../../services/playlist/PlaylistShortlistService";
import {M3uParser} from "../../lib/m3u";
import Collapsable from "../common/Collapsable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../common/IconButton";


interface Props {
    onAdded?: (newChannel: M3uMedia) => void
    useCollapsable: boolean
}

export const AddToShortlist = ({onAdded, useCollapsable}: Props) => {

    const inputCreateRefUrl = React.createRef<HTMLInputElement>();
    const inputCreateRefName = React.createRef<HTMLInputElement>();

    const addToShortlist = useCallback((channel: M3uMedia) => {
        console.log("Adding new channel to shortlist: ", channel.name, " - ", channel.location, " - ", channel.mimeType, " - ", channel.group, " -")
        playlistShortlistService.add(channel).then(() => {
            if (onAdded) {
                onAdded(channel)
            }
        });
    }, []);

    const saveToShortlist = () => {
        const url = inputCreateRefUrl.current?.value;
        const name = inputCreateRefName.current?.value;

        if (url && name) {
            var newChannel: M3uMedia = new M3uMedia()
            newChannel.location = url
            newChannel.mimeType = M3uParser.mimeTypeExtractor(url)
            newChannel.name = name
            addToShortlist(newChannel)

            // Clear input fields after successful addition
            if (inputCreateRefUrl.current) inputCreateRefUrl.current.value = '';
            if (inputCreateRefName.current) inputCreateRefName.current.value = '';
        } else {
            console.warn("URL and name are required to add a channel to the shortlist.");
            // Optionally, you can add some user feedback here, like an alert or a toast notification
        }
    }

    const doFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        saveToShortlist();
    };

    const formContent = (

        <div className={styles.formContainer}>
            <form onSubmit={doFormSubmit} className={styles.formstyling}>
                <Input id="title" placeholder="Title" innerRef={inputCreateRefName} name="title" type="text"/>
                <Input id="url" placeholder="Url" innerRef={inputCreateRefUrl} name="url" type="text"/>
                <IconButton onClick={saveToShortlist} type="button" tooltip="Legg til shortlist"
                            icon={<FontAwesomeIcon icon={faPlus}/>}/>
            </form>
        </div>
    );

    if (useCollapsable) {
        return (
            <Collapsable defaultExpanded={false} maximizedText={' - Legg til shortlist'}
                         minimizedText={'+ Legg til shortlist'}>
                {formContent}
            </Collapsable>
        );
    } else {
        return formContent;
    }
}