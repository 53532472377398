import React, {PropsWithChildren} from 'react';

import styles from "./collapsable.module.css";
import {useCollapse} from "react-collapsed";

interface CollapsableProps extends PropsWithChildren<any> {
    minimizedText: string,
    maximizedText: string,
    defaultExpanded?: boolean,
}

const Collapsable: React.FC<CollapsableProps> = ({minimizedText, maximizedText, defaultExpanded = false, children}) => {
    const {getCollapseProps, getToggleProps, isExpanded} = useCollapse({defaultExpanded: defaultExpanded});

    return (
        <>
            <div className={`${styles.collapsible}`}>
                <div className={`${styles.header}`}  {...getToggleProps()}>
                    <span className={styles.headerText}>
                        {isExpanded ? maximizedText : minimizedText}
                    </span>
                </div>
                <div {...getCollapseProps()}>
                    <div className={`${styles.content}`}>
                        {children}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Collapsable;
