import {M3uMedia, M3uPlaylist} from "../../lib/m3u";
import {IndexedDbFactory} from "../database/IndexedDbFactory";
import {ErrorResponse} from "../../hooks/fetch/types";

class PlaylistShortlistService {

    private static _instance: PlaylistShortlistService;

    private constructor() {
        //Private constructor to prevent direct instantiation
    }

    public static get Instance()
    {
        return this._instance || (this._instance = new this());
    }

    public async getPlaylist(): Promise<M3uPlaylist> {
        console.log(`PlaylistShortlistService.getPlaylist()`);
        try {
            const data = await IndexedDbFactory.getInstance().getAll("playlist_shortlist");
            return new M3uPlaylist("Shortlist", data);
        } catch (error) {
            console.log(`Error from getting value: ${error}`);
            throw {
                status: 500,
                statusText: JSON.stringify(error)
            } as ErrorResponse;
        }

    }

    public async add(channel: M3uMedia): Promise<void> {
        console.log(`PlaylistService.save()`);
        try {
            await IndexedDbFactory.getInstance().set("playlist_shortlist", channel, channel.name);
            return
        } catch (error) {
            console.log(`Error from getting value: ${error}`);
            throw {
                status: 500,
                statusText: JSON.stringify(error)
            } as ErrorResponse;
        }
    }

    public async remove(channel: M3uMedia): Promise<void> {
        console.log(`PlaylistService.remove() - channel: ${channel.name}`);
        try {
            await IndexedDbFactory.getInstance().delete("playlist_shortlist", channel.name!);
            console.log(`Kanal fjernet fra shortlist`);
            return
        } catch (error) {
            console.log(`Error from removeing value: ${error}`);
            throw {
                status: 500,
                statusText: JSON.stringify(error)
            } as ErrorResponse;
        }
    }


}

export const playlistShortlistService = PlaylistShortlistService.Instance;