import React, {useState} from 'react';
import mpegts from "mpegts.js";
import Mpegts from "mpegts.js";
import styles from "./videoplayer.module.css";
import {MimeType} from "../../lib/m3u/m3u-model";
import {SelectedChannelContext} from "../../context/channel/SelectedChannelContext";
import {
    faPlay,
    faPause,
    faAlignLeft,
    faAlignRight,
    faStop, faUpRightAndDownLeftFromCenter
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import IconButton from "../common/IconButton";


interface Props {
    url?: string;
    videoFormat: MimeType;
}

const videoPlayerConfig: Mpegts.Config = {
    enableWorker: true,
    liveBufferLatencyChasing: false, //False give better flow in the image. No loading indicator evry 5 seconds.
    liveBufferLatencyMaxLatency: 1.5,
    liveBufferLatencyMinRemain: 0.5,
    autoCleanupSourceBuffer: true,
    fixAudioTimestampGap: true,
}

export const VideoPlayer = ({url, videoFormat}: Props) => {
    const { removeSelectedChannel} = React.useContext(SelectedChannelContext);
    const videoRef = React.createRef<HTMLVideoElement>();
    const [isPaused, setIsPaused] = useState(videoRef.current?.paused);
    const [isAlignRight, setIsAlignRight] = useState(false);
    const [videoSize, setVideoSize] = useState({ width: 500, height: 250 });
    const [resizeFrame, setResizeFrame] = useState({ width: 0, height: 0, top: 0, left: 0, visible: false });
    let player: Mpegts.Player;

    React.useEffect(() => {
        console.log(`******** Videoplayer - videoFormat: ${videoFormat}`)
        const mediaDataSource: Mpegts.MediaDataSource = {
            type: videoFormat, //'mp4', //'m2ts',  // could also be mpegts, m2ts, flv, mp4
            isLive: true,
            url: url
        }
        player = mpegts.createPlayer(mediaDataSource, videoPlayerConfig);

        // console.log(`mpegts.getFeatureList(): ${JSON.stringify(mpegts.getFeatureList())}`)
        player.attachMediaElement(videoRef.current!!);
        player.load();
        var playPromise = player.play();
        if (playPromise !== undefined) {
            playPromise.then(_ => {
                console.log("Automatic playback started! Show playing UI.")
            })
                .catch(error => {
                    console.log(`Auto-play was prevented! Show paused UI. Error: ${error}`)
                    // Auto-play was prevented. Show paused UI.
                });
        }

        player.on(Mpegts.Events.LOADING_COMPLETE, function (message: any) {
            console.log(`********** Player.on Mpegts.Events.LOADING_COMPLETE. message: ${message}`)
        });
        player.on(Mpegts.Events.RECOVERED_EARLY_EOF, function (message: any) {
            console.log(`********** Player.on Mpegts.Events.RECOVERED_EARLY_EOF. message: ${message}`)
        });
        player.on(Mpegts.Events.MEDIA_INFO, function (mediaInfo: any) {
            console.log(`********** Player.on Mpegts.Events.MEDIA_INFO. message: ${JSON.stringify(mediaInfo)}`)
            //TODO: Vis data om videoen.
            //mediaInfo format: {"mimeType":"video/mp2t; codecs=\"avc1.640020,mp4a.40.2\"","duration":null,"hasAudio":true,"hasVideo":true,"audioCodec":"mp4a.40.2","videoCodec":"avc1.640020","audioDataRate":null,"videoDataRate":null,"audioSampleRate":48000,"audioChannelCount":2,"width":1280,"height":720,"fps":50,"profile":"High","level":"3.2","refFrames":4,"chromaFormat":"4:2:0","sarNum":1,"sarDen":1,"metadata":null,"segmentCount":1,"hasKeyframesIndex":null}
        });
        player.on(Mpegts.Events.METADATA_ARRIVED, function (metadata: any) {
            console.log(`********** Player.on Mpegts.Events.METADATA_ARRIVED. message: ${JSON.stringify(metadata)}`)
        });
        player.on(Mpegts.Events.SCRIPTDATA_ARRIVED, function (data: any) {
            // console.log(`********** Player.on Mpegts.Events.SCRIPTDATA_ARRIVED. message: ${JSON.stringify(data)}`)
        });
        player.on(Mpegts.Events.TIMED_ID3_METADATA_ARRIVED, function (timed_id3_metadata: any) {
            // console.log(`********** Player.on Mpegts.Events.TIMED_ID3_METADATA_ARRIVED. message: ${JSON.stringify(timed_id3_metadata)}`)
        });
        player.on(Mpegts.Events.SMPTE2038_METADATA_ARRIVED, function (smpte2038_metadata: any) {
            // console.log(`********** Player.on Mpegts.Events.SMPTE2038_METADATA_ARRIVED. message: ${JSON.stringify(smpte2038_metadata)}`)
        });
        player.on(Mpegts.Events.PES_PRIVATE_DATA_DESCRIPTOR, function (descriptor: any) {
            // console.log(`********** Player.on Mpegts.Events.PES_PRIVATE_DATA_DESCRIPTOR. message: ${JSON.stringify(descriptor)}`)
        });
        player.on(Mpegts.Events.PES_PRIVATE_DATA_ARRIVED, function (private_data: any) {
            // console.log(`********** Player.on Mpegts.Events.PES_PRIVATE_DATA_ARRIVED. message: ${JSON.stringify(private_data)}`)
        });
        player.on(Mpegts.Events.STATISTICS_INFO, function (_statisticsInfo: any) {
            // console.log(`********** Player.on Mpegts.Events.STATISTICS_INFO. message: ${JSON.stringify(_statisticsInfo)}`)
        });
        player.on(Mpegts.Events.ERROR, function (errorType: Mpegts.ErrorTypes, details: string, info: string) {
            console.log(`********** Player.on Mpegts.Events.ERROR. ErrorType: ${errorType}`)
            console.log(`********** Player.on Mpegts.Events.ERROR. details: ${JSON.stringify(details)}`)
            console.log(`********** Player.on Mpegts.Events.ERROR. info: ${JSON.stringify(info)}`)
        });
        return function cleanup() {
            console.log(`********** VideoPlayer.useEffect - cleanup`);
            player.destroy()
        };
    }, [url]);

    const togglePause = () => {
        setIsPaused(!isPaused)
        if (!isPaused) {
            videoRef.current?.pause()
        } else {
            videoRef.current?.play()
        }
    }

    const toggleAlign = () => {
        setIsAlignRight(!isAlignRight)
    }

    const startResize = (e: React.MouseEvent) => {
        e.preventDefault();
        const startX = e.clientX;
        const startWidth = videoSize.width;
        const startHeight = videoSize.height;
        const rect = videoRef.current?.getBoundingClientRect();

        setResizeFrame({
            width: startWidth,
            height: startHeight,
            top: rect?.top || 0,
            left: rect?.left || 0,
            visible: true
        });

        const onMouseMove = (e: MouseEvent) => {
            const deltaX = e.clientX - startX;
            const aspectRatio = startWidth / startHeight;

            const newWidth = Math.max(startWidth + deltaX, 100);
            const newHeight = Math.round(newWidth / aspectRatio);

            setResizeFrame(prev => ({
                ...prev,
                width: newWidth,
                height: newHeight,
            }));
        };

        const onMouseUp = (e: MouseEvent) => {
            const deltaX = e.clientX - startX;
            const aspectRatio = startWidth / startHeight;

            // Use the larger delta to determine the new size
            const newWidth = Math.max(startWidth + deltaX, 100);
            const newHeight = Math.round(newWidth / aspectRatio);

            setVideoSize({
                width: newWidth,
                height: newHeight
            });

            setResizeFrame({ width: 0, height: 0, top: 0, left:0, visible: false });

            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        };

        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
    };

    return (
        <>
            <div className="align_horizontally">
                <IconButton onClick={removeSelectedChannel} type="button" tooltip="Stopp video"
                            icon={<FontAwesomeIcon icon={faStop} />} />

                <IconButton onClick={togglePause} type="button" tooltip={isPaused ? "Play" : "Pause"}
                            icon={isPaused ? <FontAwesomeIcon icon={faPlay} /> : <FontAwesomeIcon icon={faPause} />} />

                <IconButton onClick={toggleAlign} type="button" tooltip={isAlignRight ? "Align left" : "Align right"}
                            icon={isAlignRight ? <FontAwesomeIcon icon={faAlignLeft} /> : <FontAwesomeIcon icon={faAlignRight} />} />
            </div>

            <div className={`${styles.videoContainer} ${isAlignRight ? styles.alignRight : styles.alignLeft}`}>
                <video
                    controls
                    ref={videoRef}
                    id="videoElement"
                    style={{width: `${videoSize.width}px`, height: `${videoSize.height}px`}}>
                </video>
                <div
                    className={` ${styles.resizeHandleCorner}`}
                    onMouseDown={(e) => startResize(e)}>
                    <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} rotation={90} className={styles.resizeIcon}/>
                </div>
                {resizeFrame.visible && (
                    <div
                        className={styles.resizeFrame}
                        style={{
                            width: `${resizeFrame.width}px`,
                            height: `${resizeFrame.height}px`,
                            top: `${resizeFrame.top}px`,
                            left: `${resizeFrame.left}px`,
                        }}
                    >
                        {`${resizeFrame.width} x ${resizeFrame.height}`}
                    </div>
                )}

            </div>
        </>
    );
}

export default VideoPlayer;