import React, {PropsWithChildren, useState} from 'react';
import Streaming from "../../components/Streaming";
import styles from "./AllChannels.module.css";
import ChannelsList from "../../components/ChannelsList";
import {playlistService} from "../../services/playlist/PlaylistService";
import {M3uMedia} from "../../lib/m3u";
import {playlistShortlistService} from "../../services/playlist/PlaylistShortlistService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBookmark} from "@fortawesome/free-solid-svg-icons";
import {WordFilter} from "../../components/WordFilter";

interface Props extends PropsWithChildren<any> {

}

function AllChannels(props: Props) {

    const [filterWords, setFilterWords] = useState<string[]>([]);

    const addToShortlist = (channel: M3uMedia) => {
        console.log(`Clicked on ${channel.name}`)
        playlistShortlistService.add(channel)
    };

    const ChannelActions = ({ data }: { data: M3uMedia }) => (
        <div className={styles.channelActions}>
            <div className={styles.tooltipContainer} title="Legg til shortlist">
                <FontAwesomeIcon
                    icon={faBookmark}
                    onClick={() => addToShortlist(data)}
                    className={styles.addIcon}
                />
            </div>
        </div>
    );

    const handleFilterSubmit = (words: string[]) => {
        console.log("Filter submitted with words:", words);
        setFilterWords(words);
    };

    return (
        <div className={styles.boxcontainer}>
            <div className={styles.box}>
                <WordFilter onSubmit={handleFilterSubmit}/>
                <ChannelsList wordsFilter={filterWords} RowActionsComponent={ChannelActions} playlistService={playlistService}/>
            </div>
            <div className={`${styles.box}`}>
                <Streaming/>
            </div>
        </div>
    );
}

export default AllChannels;
