import React, {PropsWithChildren, useEffect, useReducer} from 'react';
import styles from "./channels.module.css";
import "../../App.css";
import {M3uMedia, M3uPlaylist} from "../../lib/m3u";
import {getFetchError, getFetchLoading, getFetchSuccess} from "../../hooks/fetch/actions";
import {ErrorResponse} from "../../hooks/fetch/types";
import {fetchReducer, initialFetchState} from "../../hooks/fetch/fetchReducer";
import {ShowPlaylist} from "./ShowPlaylist";
import Loading from "../common/Loading";

interface Props extends PropsWithChildren<any> {
    playlistService: {
        getPlaylist: () => Promise<M3uPlaylist>;
    };
    RowActionsComponent?: React.ComponentType<{data: M3uMedia}>; // New property
    wordsFilter: string[]
}

function ChannelsList({playlistService, RowActionsComponent, wordsFilter}: Props) {
    const [playlist, dispatch] = useReducer(fetchReducer<M3uPlaylist>(), initialFetchState);

    useEffect(() => {
        dispatch(getFetchLoading<M3uPlaylist>());

        playlistService.getPlaylist()
            .then((playlist: M3uPlaylist) => dispatch(getFetchSuccess(playlist)))
            .catch((error: ErrorResponse) => dispatch(getFetchError(error)))

    }, [playlistService]);

    const showContent = () => {
        if (playlist.isLoading) {
            return <Loading/>
        }
        if (playlist.error) {
            return <div className={styles.list}>Lasting feilet: {JSON.stringify(playlist.error)} </div>
        }
        if (!playlist.data) {
            return <div className={styles.list}>Ikke lastet ennå...</div>
        }

        return <ShowPlaylist wordsFilter={wordsFilter} RowActionsComponent={RowActionsComponent} playlist={playlist.data}/>
    }


    return (
        <>
            {showContent()}
        </>
    );
}

export default ChannelsList;
