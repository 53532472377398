import React from 'react';

import styles from "./iconButton.module.css";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    tooltip: string;
    icon: React.ReactNode;
}

const IconButton: React.FC<ButtonProps> = ({tooltip, icon, ...props}) => {

    return (
        <div className={`${styles.iconbutton__wrapper}`}>
            <button {...props} className={`${styles.button}`} title={tooltip}>
                <span>{icon}</span>
            </button>
        </div>
    );
}

export default IconButton;
