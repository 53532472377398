import {IndexedDbFactory} from "../database/IndexedDbFactory";
import {ErrorResponse} from "../../hooks/fetch/types";
import {M3uPlaylist} from "../../lib/m3u";


class PlaylistService {

    private static _instance: PlaylistService;

    private constructor() {
        //Private constructor to prevent direct instantiation
    }

    public static get Instance()
    {
        return this._instance || (this._instance = new this());
    }

    public async getPlaylist(): Promise<M3uPlaylist> {
        console.log(`PlaylistService.getPlaylist()`);
        try {
            const data = await IndexedDbFactory.getInstance().get("keyvalue", "playlist");
            return data
        } catch (error) {
            console.log(`Error from getting value: ${error}`);
            throw {
                status: 500,
                statusText: JSON.stringify(error)
            } as ErrorResponse;
        }

    }

    public async countChannelsInPlaylist(): Promise<number> {
        console.log(`PlaylistService.countChannelsInPlaylist()`);
        try {
            const data: M3uPlaylist = await IndexedDbFactory.getInstance().get("keyvalue", "playlist");
            const length = data.medias.length;
            console.log(`Antall kanaler lagret i databasen: ${length}`);
            return length
        } catch (error) {
            console.log(`Error from getting value: ${error}`);
            throw {
                status: 500,
                statusText: JSON.stringify(error)
            } as ErrorResponse;
        }

    }

    public async save(playlist: M3uPlaylist): Promise<void> {
        console.log(`PlaylistService.save()`);
        try {
            await IndexedDbFactory.getInstance().set("keyvalue", playlist, "playlist");
            return
        } catch (error) {
            console.log(`Error from getting value: ${error}`);
            throw {
                status: 500,
                statusText: JSON.stringify(error)
            } as ErrorResponse;
        }
    }

    public async remove(): Promise<void> {
        console.log(`PlaylistService.remove()`);
        try {
            await IndexedDbFactory.getInstance().delete("keyvalue", "playlist");
            console.log(`Spilleliste fjernet`);
            return
        } catch (error) {
            console.log(`Error from getting value: ${error}`);
            throw {
                status: 500,
                statusText: JSON.stringify(error)
            } as ErrorResponse;
        }
    }


}

export const playlistService = PlaylistService.Instance;
