import React from 'react';
import styles from "./SelectedChannelName.module.css";
import {SelectedChannelContext} from "../../context/channel/SelectedChannelContext";

function SelectedChannelName() {
    const { data} = React.useContext(SelectedChannelContext);
    console.log(`State from ChannelContext: ${JSON.stringify(data)}`);

    if (data == null) {
        return null
    }
    return (

        <div className={styles.centered}>
            <div className={styles.name}>{data.name}</div>
        </div>
    );
}

export default SelectedChannelName;
