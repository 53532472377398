import styles from "./channels.module.css";
import AutoSizer from "react-virtualized-auto-sizer";
import {FixedSizeList as List} from "react-window";
import {Row} from "./ChannelItem";
import React, {useEffect, useState} from "react";
import {M3uMedia, M3uPlaylist} from "../../lib/m3u";

type ItemProps = {
    playlist: M3uPlaylist;
    RowActionsComponent?: React.ComponentType<{data: M3uMedia}>; // New property
    wordsFilter: string[]
}

export const ShowPlaylist = ({playlist, RowActionsComponent, wordsFilter}: ItemProps) => {
    const [filteredChannels, setFilteredChannels] = useState(playlist ? playlist.medias : []);
    const allChannels = playlist ? playlist.medias : [];

    //useEffect for filtering playlist:
    useEffect(() => {
        console.log(`Number of channels before filter: ${allChannels.length}`)
        const result = applyFilters(allChannels, wordsFilter);
        console.log(`Number of channels after filter: ${result.length}`)
        setFilteredChannels(result);
    }, [wordsFilter, allChannels]);

    function filter(channels: M3uMedia[], filterValue: string): M3uMedia[] {
        return channels.filter(({location, mimeType, name}) =>
            (!filterValue || location?.toLowerCase().search(filterValue.toLowerCase()) !== -1) ||
            (!filterValue || mimeType.toLowerCase().search(filterValue.toLowerCase()) !== -1) ||
            (!filterValue || name?.toLowerCase().search(filterValue.toLowerCase()) !== -1)
        );
    }

    /**
     * Advanced filter function. Will filter on name from all filterValues
     * @param channels
     * @param filterValues
     */
    function applyFilters(channels: M3uMedia[], filterValues: string[]): M3uMedia[] {
        let filteredChannels: M3uMedia[] = channels
        filterValues.forEach(value =>
        {
            filteredChannels = filter(filteredChannels, value);
        })
        return filteredChannels
    }

    // Create a wrapper function for Row that includes onIndexClick
    const RowWrapper = React.useCallback(
        ({ data, index, style }: any) => (
            <Row
                data={data}
                index={index}
                style={style}
                RowActionsComponent={RowActionsComponent}
            />
        ),
        [RowActionsComponent]
    );

    return (
        <>
            <AutoSizer>
            {({height, width}: any) => (
                    <div>

                        <List
                            className={styles.list}
                            height={height}
                            itemData={filteredChannels}
                            itemCount={filteredChannels.length}
                            itemSize={50}
                            width={width}>
                            {RowWrapper}
                        </List>
                    </div>
                )}
            </AutoSizer>
        </>
    )
};