import React from 'react';
import {createHashRouter, Outlet, RouterProvider} from "react-router-dom";
import styles from './App.module.css';
import Home from "./pages/Home";
import Header from "./components/Header/Header";
import AllChannels from "./pages/AllChannels";
import SelectedChannelProvider from "./context/channel/SelectedChannelContext";
import AccountProvider from "./context/account/AccountContext";
import Shortlist from "./pages/Shortlist";

function App() {
    const Layout = () => (
        <div className={styles.appContainer}>
            <Header/>
            <main className={styles.mainContent}>
                <Outlet/>
            </main>
        </div>
    );

    const routers = createHashRouter([
        {
            element: <Layout/>,
            children: [
                {
                    path: "/",
                    element: <Home/>
                },
                {
                    path: "/allChannels",
                    element: <AllChannels/>
                },
                {
                    path: "/shortlist",
                    element: <Shortlist/>
                }

            ]
        }
    ]);

    return (
        <div className={styles.viewport}>
            <AccountProvider>
                <SelectedChannelProvider>
                    <RouterProvider router={routers}/>
                </SelectedChannelProvider>
            </AccountProvider>
        </div>
    );
}

export default App;
