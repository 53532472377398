import React from 'react';
import "../../App.css";
import {AccountContext} from "../../context/account/AccountContext";
import DownloadPlaylistWithAccountData from "./DownloadPlaylistWithAccountData";

function DownloadPlaylist() {
    const { data} = React.useContext(AccountContext);

    const showContent = () => {
        if (data) {
            return <DownloadPlaylistWithAccountData/>
        } else {
            // return <DownloadPlaylistFromInput/>
            return null
        }
    }

    return (
        <>
            {showContent()}
        </>
    );
}

export default DownloadPlaylist;
