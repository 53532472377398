import React from 'react';

import styles from "./header.module.css";
import { NavLink } from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTv} from "@fortawesome/free-solid-svg-icons";

function Header() {

  return (
    <>
    <header className={styles.header}>
      <div className={styles.logoContainer}>
        <FontAwesomeIcon icon={faTv} className={styles.logo}/>
      </div>
      <div id="menubar" className={styles.menu}>
        <nav>
          <ul>
            <li>
            <NavLink
                      to="/"
                      className={({isActive}) =>
                          isActive ? styles.activeLink : undefined
                      }
                  >
                    Innstillinger
                  </NavLink>
                </li>
                <li>
                  <NavLink
                      to="/allChannels"
                      className={({isActive}) =>
                          isActive ? styles.activeLink : undefined
                      }
                  >
                    Alle kanaler
                  </NavLink>
                </li>
                <li>
                  <NavLink
                      to="/shortlist"
                      className={({isActive}) =>
                          isActive ? styles.activeLink : undefined
                      }
                  >
                    Shortlist
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
    </header>
    </>
  );
}

export default Header;
