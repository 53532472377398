import React, {CSSProperties} from "react";
import {M3uMedia} from "../../lib/m3u";
import styles from "./channels.module.css";
import {SelectedChannelContext} from "../../context/channel/SelectedChannelContext";

type ItemProps = {
    index: number,
    style: CSSProperties | undefined;
    data: M3uMedia;
    onRowClick?: (data: M3uMedia) => void;
    RowActionsComponent?: React.ComponentType<{data: M3uMedia}>; // New property
}

function ChannelItem({data, index, style, onRowClick = () => {}, RowActionsComponent}: ItemProps) {
    const keyName = data.name ? data.name : "";

    return (

        <div style={style} key={index + keyName} className={styles.channel_item_container}>
            {RowActionsComponent && (
                <div className={styles.firstCol}>
                    <RowActionsComponent data={data} />
                </div>
            )}
            <div className={styles.content} onClick={() => onRowClick(data)}>
                <div className={styles.name}>{data.name}</div>
                <div className={styles.location}>{data.location}</div>
            </div>
        </div>
    )
}

export const Row = ({data, index, style, RowActionsComponent}: any) => {
    const {selectChannel} = React.useContext(SelectedChannelContext);
    const item = data[index];
    const onRowClick = (rowData: M3uMedia) => {
        selectChannel(rowData)
    };

    return (
        <ChannelItem RowActionsComponent={RowActionsComponent} index={index} style={style} onRowClick={onRowClick} data={item}></ChannelItem>
    )
};