import React, {useCallback} from "react";
import Button from "../common/Button";
import Input from "../common/Input";
import styles from "./wordfilter.module.css";

type ItemProps = {
    onSubmit: (words: string[]) => void; // Changed to onSubmit
}

export const WordFilter = ({onSubmit}: ItemProps) => {
    const inputCreateRef1 = React.createRef<HTMLInputElement>();
    const inputCreateRef2 = React.createRef<HTMLInputElement>();

    const doFilter = useCallback((e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const value1 = inputCreateRef1.current?.value || "";
        const value2 = inputCreateRef2.current?.value || "";
        const words = [value1, value2].filter(word => word.trim() !== "");
        onSubmit(words); // Call the callback with the new search words
    }, [onSubmit]);

    return (
        <div className={styles.wordFilterContainer}>
            <form onSubmit={doFilter} className={styles.filterForm}>
                <Input id="filter" placeholder="Filter 1" innerRef={inputCreateRef1} name="filter" type="text"/>
                <Input id="filter2" placeholder="Filter 2" innerRef={inputCreateRef2} name="filter" type="text"/>
                <Button type="submit" text="Filter"/>
            </form>
        </div>
    )
};