import React from 'react';
import VideoPlayer from "../VideoPlayer";
import styles from "./streaming.module.css";
import {SelectedChannelContext} from "../../context/channel/SelectedChannelContext";
import SelectedChannelName from "../SelectedChannelName";

function Streaming() {
    const { data} = React.useContext(SelectedChannelContext);
    console.log(`State from ChannelContext: ${JSON.stringify(data)}`);

    return (
        <div className={styles.centered}>
            <SelectedChannelName />
            { data && <VideoPlayer url={data.location} videoFormat={data.mimeType}/> }
        </div>
    );
}

export default Streaming;
